import axios from "axios";

const ApiService = {
  init() {
    axios.defaults.headers.common['Authorization'] = `Bearer 9CBkNGvflSk3W5CkmDS0VIObH3`;
    axios.defaults.baseURL = "https://admin.giving.auk.edu.krd";
    // axios.defaults.baseURL = "http://127.0.0.1:8000";
    // axios.defaults.baseURL = "http://auk-giving-backend.test";
  },

  async getPage(page) {
    try {
      return await axios.get("/api/v1/pages/" + page);
    } catch (e) {
      return e.response;
    }
  },

  async authorizePayment(data) {
    try {
      return await axios.post("/api/v1/pages/payment/process", data);
    } catch (e) {
      return e.response;
    }
  },
  async doSubscribe(data) {
    try {
      return await axios.post("/api/v1/pages/doSubscribe", data);
    } catch (e) {
      return e.response;
    }
  },
  async sendContact(data) {
    try {
      return await axios.post("/api/v1/pages/sendContact", data);
    } catch (e) {
      return e.response;
    }
  },
  async sendGiveThroughFIB(data) {
    try {
      return await axios.post("/api/v1/pages/sendGiveThroughFIB", data);
    } catch (e) {
      return e.response;
    }
  },
  async checkPaymentStatusGiveThroughFIB(data, uuid) {
    try {
      return await axios.get(`/api/v1/give-via-fib/check-payment-status/${uuid}`, data);
    } catch (e) {
      return e.response;
    }
  },
  async areebaPayment(data) {
    try {
      return await axios.post("/api/v1/pages/areebaPayment", data);
    } catch (e) {
      return e.response;
    }
  },
  async authorizePaymentSaveData(data) {
    try {
      return await axios.post("/api/v1/pages/authorizePaymentSaveData", data);
    } catch (e) {
      return e.response;
    }
  },
};

export default ApiService;
