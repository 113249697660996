<template>
  <router-view :key="$route.fullPath" />
  <teleport to="body">
    <vue3-snackbar bottom right :duration="4000"></vue3-snackbar>
  </teleport>
</template>

<script>
export default {
  name: "App",
  components: {},
};
</script>

<style>
@import "assets/css/bootstrap.min.css";
@import "assets/css/xsIcon.css";
@import "assets/css/font-awesome.min.css";
@import "assets/css/isotope.css";
@import "assets/css/magnific-popup.css";
@import "assets/css/plugins.css";
@import "animate.css";
@import "assets/css/navigation.min.css";
@import "assets/css/style.css";
@import "assets/css/responsive.css";
@import "assets/css/colors/color-1.css";
@import url("https://fonts.googleapis.com/css2?family=Noto+Sans:ital,wght@0,100..900;1,100..900&display=swap");
.color-red {
  color: #8a0021 !important;
}
body {
  font-family: "Noto Sans", sans-serif;
  font-optical-sizing: auto;
  overflow-x: hidden !important;
}

input:focus {
  border: 1px solid #cdcdcd !important;
}

select {
  border: 1px solid #cdcdcd !important;
}

img {
  border-radius: 4px !important;
}

html {
  overflow-x: hidden !important;
}

.btn {
  border-radius: 0px !important;
  border-radius: 3px !important;
}

.content-wrap h3 {
  font-size: 1.85714em;
  /* text-transform: uppercase; */
  font-weight: 600;
}

.content-wrap h5 {
  font-size: 21px !important;
  font-weight: bold;
}

.content-wrap p {
  font-size: 18px !important;
}
/* .lead h3{
  font-size: 34px !important;
}

.lead ,p{
  font-size: 18px  !important;
} */

/* .btn {
    background-color: rgb(138, 0, 33) !important;
    color: #fff;
    border: 1px solid rgb(138, 0, 33);
    font-size: 16px;
    padding: 10px 30px !important;

}

.btn::after {
    background-color: rgb(138, 0, 33) !important;
} */


.custom-list-group {
    list-style: disc !important;
    padding-left: 20px;
}

.custom-list-group li {
    display: list-item;
}

@media only screen and (max-width: 767px) {
  .blog_title {
    font-size: 20px !important;
  }
  iframe {
    width: 100%;
    height: calc(100vw * 9 / 16);
    max-height: 100%;
  }
}
</style>
